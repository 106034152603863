export const schemas = 
{
  "iglu:com.snowplowanalytics.mobile/screen_end/jsonschema/1-0-0": {
    "type": "object",
    "properties": {},
    "additionalProperties": false,
    "description": "Schema for an event tracked before transitioning to a new screen",
    "$schema": "iglu:com.snowplowanalytics.mobile/screen_end/jsonschema/1-0-0"
  },
  "iglu:com.snowplowanalytics.mobile/screen_summary/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "foreground_sec": {
        "type": "number",
        "minimum": 0,
        "maximum": 2147483647,
        "description": "Time in seconds spent on the current screen while the app was in foreground"
      },
      "background_sec": {
        "anyOf": [
          {
            "type": "number",
            "minimum": 0,
            "maximum": 2147483647,
            "description": "Time in seconds spent on the current screen while the app was in background"
          },
          {
            "type": "null",
            "description": "Time in seconds spent on the current screen while the app was in background"
          }
        ],
        "description": "Time in seconds spent on the current screen while the app was in background"
      },
      "last_item_index": {
        "anyOf": [
          {
            "type": "integer",
            "minimum": 0,
            "maximum": 65535,
            "description": "Index of the last viewed item in the list on the screen"
          },
          {
            "type": "null",
            "description": "Index of the last viewed item in the list on the screen"
          }
        ],
        "description": "Index of the last viewed item in the list on the screen"
      },
      "items_count": {
        "anyOf": [
          {
            "type": "integer",
            "minimum": 0,
            "maximum": 65535,
            "description": "Total number of items in the list on the screen"
          },
          {
            "type": "null",
            "description": "Total number of items in the list on the screen"
          }
        ],
        "description": "Total number of items in the list on the screen"
      },
      "min_x_offset": {
        "anyOf": [
          {
            "type": "integer",
            "minimum": -2147483647,
            "maximum": 2147483647,
            "description": "Minimum horizontal scroll offset on the scroll view in pixels"
          },
          {
            "type": "null",
            "description": "Minimum horizontal scroll offset on the scroll view in pixels"
          }
        ],
        "description": "Minimum horizontal scroll offset on the scroll view in pixels"
      },
      "max_x_offset": {
        "anyOf": [
          {
            "type": "integer",
            "minimum": -2147483647,
            "maximum": 2147483647,
            "description": "Maximum horizontal scroll offset on the scroll view in pixels"
          },
          {
            "type": "null",
            "description": "Maximum horizontal scroll offset on the scroll view in pixels"
          }
        ],
        "description": "Maximum horizontal scroll offset on the scroll view in pixels"
      },
      "min_y_offset": {
        "anyOf": [
          {
            "type": "integer",
            "minimum": -2147483647,
            "maximum": 2147483647,
            "description": "Minimum vertical scroll offset on the scroll view in pixels"
          },
          {
            "type": "null",
            "description": "Minimum vertical scroll offset on the scroll view in pixels"
          }
        ],
        "description": "Minimum vertical scroll offset on the scroll view in pixels"
      },
      "max_y_offset": {
        "anyOf": [
          {
            "type": "integer",
            "minimum": -2147483647,
            "maximum": 2147483647,
            "description": "Maximum vertical scroll offset on the scroll view in pixels"
          },
          {
            "type": "null",
            "description": "Maximum vertical scroll offset on the scroll view in pixels"
          }
        ],
        "description": "Maximum vertical scroll offset on the scroll view in pixels"
      },
      "content_width": {
        "anyOf": [
          {
            "type": "integer",
            "minimum": 0,
            "maximum": 2147483647,
            "description": "Width of the scroll view in pixels"
          },
          {
            "type": "null",
            "description": "Width of the scroll view in pixels"
          }
        ],
        "description": "Width of the scroll view in pixels"
      },
      "content_height": {
        "anyOf": [
          {
            "type": "integer",
            "minimum": 0,
            "maximum": 2147483647,
            "description": "Height of the scroll view in pixels"
          },
          {
            "type": "null",
            "description": "Height of the scroll view in pixels"
          }
        ],
        "description": "Height of the scroll view in pixels"
      }
    },
    "additionalProperties": false,
    "description": "Schema for an entity tracked with foreground/background/screen_end events with summary statistics about the screen view",
    "required": [
      "foreground_sec"
    ],
    "$schema": "iglu:com.snowplowanalytics.mobile/screen_summary/jsonschema/1-0-0"
  },
  "iglu:com.snowplowanalytics.mobile/screen_view/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "name": {
        "type": "string",
        "description": "The name of the screen viewed."
      },
      "type": {
        "type": "string",
        "description": "The type of screen that was viewed e.g feed / carousel."
      },
      "id": {
        "type": "string",
        "format": "uuid",
        "maxLength": 36,
        "description": "An ID from the associated screenview event."
      },
      "previousName": {
        "type": "string",
        "description": "The name of the previous screen."
      },
      "previousId": {
        "type": "string",
        "format": "uuid",
        "description": "A screenview ID of the previous screenview."
      },
      "previousType": {
        "type": "string",
        "description": "The screen type of the previous screenview."
      },
      "transitionType": {
        "type": "string",
        "description": "The type of transition that led to the screen being viewed."
      }
    },
    "additionalProperties": false,
    "description": "Schema for a screen view event",
    "required": [
      "name",
      "id"
    ],
    "$schema": "iglu:com.snowplowanalytics.mobile/screen_view/jsonschema/1-0-0"
  },
  "tvmedia/api_get_search_history_event/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "account_id": {
        "type": "string",
        "description": "Account ID of user who requested the search history."
      },
      "profile_id": {
        "type": "string",
        "description": "Profile ID of user who requested the search history."
      },
      "calling_service_name": {
        "type": "string",
        "description": "Service name calling the search service"
      },
      "client_name": {
        "type": "string",
        "description": "Source client-name calling service as passed from gateway"
      },
      "client_version": {
        "type": "string",
        "description": "Source client-version calling service as passed from gateway"
      },
      "trace_id": {
        "type": "string",
        "description": "Tracing parent for request as passed from gateway"
      },
      "history": {
        "type": "array",
        "items": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "type": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "type"
          ],
          "additionalProperties": false
        },
        "description": "Items in history"
      }
    },
    "additionalProperties": false,
    "description": "Schema for get search history events.",
    "required": [
      "account_id",
      "profile_id"
    ],
    "$schema": "tvmedia/api_get_search_history_event/jsonschema/1-0-0"
  },
  "tvmedia/api_post_search_history_event/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "account_id": {
        "type": "string",
        "description": "Account ID of user who requested the search history."
      },
      "profile_id": {
        "type": "string",
        "description": "Profile ID of user who requested the search history."
      },
      "calling_service_name": {
        "type": "string",
        "description": "Service name calling the search service"
      },
      "client_name": {
        "type": "string",
        "description": "Source client-name calling service as passed from gateway"
      },
      "client_version": {
        "type": "string",
        "description": "Source client-version calling service as passed from gateway"
      },
      "trace_id": {
        "type": "string",
        "description": "Tracing parent for request as passed from gateway"
      },
      "item": {
        "type": "object",
        "properties": {
          "id": {
            "type": "string"
          },
          "type": {
            "type": "string"
          }
        },
        "required": [
          "id",
          "type"
        ],
        "additionalProperties": false,
        "description": "Item posted to search history"
      }
    },
    "additionalProperties": false,
    "description": "Schema for posts of new search history item events.",
    "required": [
      "account_id",
      "profile_id",
      "item"
    ],
    "$schema": "tvmedia/api_post_search_history_event/jsonschema/1-0-0"
  },
  "tvmedia/api_search_query_event/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "account_id": {
        "type": "string",
        "description": "Account(User) ID for the search query"
      },
      "profile_id": {
        "type": "string",
        "description": "Profile ID for the search query"
      },
      "calling_service_name": {
        "type": "string",
        "description": "Service name calling the search service"
      },
      "client_name": {
        "type": "string",
        "description": "Source client-name calling service as passed from gateway"
      },
      "client_version": {
        "type": "string",
        "description": "Source client-version calling service as passed from gateway"
      },
      "trace_id": {
        "type": "string",
        "description": "Tracing parent for request as passed from gateway"
      },
      "types": {
        "type": "array",
        "items": {
          "type": "string"
        },
        "description": "A list of applied filters to the search query."
      },
      "query": {
        "type": "string",
        "description": "Original search query"
      },
      "normalized_query": {
        "type": "string",
        "description": "Normalized search query trimmed to acceptable size"
      },
      "filter": {
        "type": "array",
        "items": {
          "type": "string"
        },
        "description": "List of provided filters"
      },
      "limit": {
        "type": "integer",
        "minimum": 0,
        "description": "Limit of number of results"
      },
      "offset": {
        "type": "integer",
        "minimum": 0,
        "description": "Offset to be used for search"
      },
      "entitlements": {
        "type": "array",
        "items": {
          "type": "string"
        },
        "description": "Entitlement of caller"
      },
      "upsell_entitlements": {
        "type": "array",
        "items": {
          "type": "string"
        },
        "description": "Entitlement to show as upsells"
      },
      "tags": {
        "type": "array",
        "items": {
          "type": "string"
        },
        "description": "List of tags to filter search on"
      },
      "include_upsell": {
        "type": "boolean",
        "description": "Boolean marking whether to include upsells in search"
      },
      "numResults": {
        "type": "integer",
        "minimum": 0,
        "description": "Number of results returned"
      },
      "numExternalLinkResults": {
        "type": "integer",
        "minimum": 0,
        "description": "Number of external link results"
      },
      "externalLinkReplacments": {
        "type": "array",
        "items": {
          "type": "string"
        },
        "description": "List of external link replacements"
      },
      "results": {
        "type": "array",
        "items": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "relevance": {
              "type": "number",
              "minimum": 0
            },
            "source": {
              "type": "string"
            },
            "position": {
              "type": "integer",
              "minimum": 0
            },
            "fromExternalLinkId": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "relevance",
            "source",
            "position"
          ],
          "additionalProperties": false
        },
        "description": "List of matches for the search query"
      }
    },
    "additionalProperties": false,
    "description": "Schema for server side search query events.",
    "required": [
      "query",
      "results"
    ],
    "$schema": "tvmedia/api_search_query_event/jsonschema/1-0-0"
  },
  "tvmedia/search_abandonment/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "actionType": {
        "type": "string",
        "enum": [
          "backspace",
          "clear",
          "navigation",
          "exit"
        ],
        "description": "Abandonment action type"
      },
      "query": {
        "type": "string",
        "description": "The search query"
      },
      "types": {
        "type": "string",
        "description": "The filters applied to the search query"
      },
      "result_view": {
        "type": "string",
        "enum": [
          "list",
          "panel",
          "facet",
          "noinput"
        ],
        "description": "The context of the search query"
      }
    },
    "additionalProperties": false,
    "description": "Schema for search abandonment event",
    "required": [
      "actionType",
      "query",
      "types",
      "result_view"
    ],
    "$schema": "tvmedia/search_abandonment/jsonschema/1-0-0"
  },
  "tvmedia/search_abandonment/jsonschema/2-0-0": {
    "type": "object",
    "properties": {
      "action_type": {
        "type": "string",
        "enum": [
          "backspace",
          "clear",
          "navigation",
          "exit"
        ]
      },
      "query": {
        "type": "string"
      },
      "types": {
        "type": "string"
      },
      "view": {
        "type": "string",
        "enum": [
          "list",
          "panel",
          "facet",
          "no_input"
        ]
      }
    },
    "additionalProperties": false,
    "description": "See https://tv4.notion.site/Search-Client-guide-b1da6c4b4deb40e9bafc5a83f18d23df for documentation.",
    "required": [
      "action_type",
      "view"
    ],
    "$schema": "tvmedia/search_abandonment/jsonschema/2-0-0"
  },
  "tvmedia/search_abandonment/jsonschema/3-1-0": {
    "type": "object",
    "properties": {
      "action_type": {
        "type": "string",
        "enum": [
          "backspace",
          "clear"
        ],
        "description": "The type of action that the user has taken."
      },
      "types": {
        "type": "array",
        "items": {
          "type": "string",
          "enum": [
            "Movie",
            "Series",
            "Clip",
            "SportEvent",
            "PageReference",
            "Short"
          ],
          "description": "A list of applied filters to the search query."
        }
      },
      "view": {
        "type": "string",
        "enum": [
          "list",
          "grid",
          "no_input"
        ],
        "description": "The type of search layout that the user is currently using"
      },
      "query": {
        "type": "string",
        "description": "The search query that the user has entered"
      },
      "app_version": {
        "type": "string",
        "description": "The version of the app that the user is using."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "See https://tv4.notion.site/Search-Client-guide-b1da6c4b4deb40e9bafc5a83f18d23df for documentation.",
    "required": [
      "action_type",
      "types",
      "view",
      "query",
      "app_version"
    ],
    "$schema": "tvmedia/search_abandonment/jsonschema/3-1-0"
  },
  "tvmedia/search_exit/jsonschema/1-0-0": {
    "type": "object",
    "properties": {},
    "additionalProperties": false,
    "description": "Schema for search exit event",
    "$schema": "tvmedia/search_exit/jsonschema/1-0-0"
  },
  "tvmedia/search_exit/jsonschema/1-0-1": {
    "type": "object",
    "properties": {
      "query": {
        "type": "string",
        "description": "The related search query"
      },
      "types": {
        "type": "string",
        "description": "The filters applied to the search query"
      },
      "result_view": {
        "type": "string",
        "enum": [
          "list",
          "panel",
          "facet"
        ],
        "description": "The context of the related search query"
      }
    },
    "additionalProperties": false,
    "description": "Schema for search exit event",
    "required": [
      "query",
      "types",
      "result_view"
    ],
    "$schema": "tvmedia/search_exit/jsonschema/1-0-1"
  },
  "tvmedia/search_exit/jsonschema/3-1-0": {
    "type": "object",
    "properties": {
      "query": {
        "type": "string",
        "description": "The related search query"
      },
      "types": {
        "type": "array",
        "items": {
          "type": "string",
          "enum": [
            "Movie",
            "Series",
            "Clip",
            "SportEvent",
            "PageReference",
            "Short"
          ]
        },
        "description": "A list of applied filters to the search query."
      },
      "view": {
        "type": "string",
        "enum": [
          "list",
          "grid"
        ],
        "description": "The context of the related search query"
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "See https://tv4.notion.site/Nordic-Search-Client-guide-V3-1-be8b3af103fb4e16b163d9460a70f6af?pvs=74 for documentation.",
    "required": [
      "query",
      "types",
      "view"
    ],
    "$schema": "tvmedia/search_exit/jsonschema/3-1-0"
  },
  "tvmedia/search_list_result_click/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string",
        "description": "The id of the clicked result"
      },
      "type": {
        "type": "string",
        "description": "The type of the clicked result"
      },
      "position": {
        "type": "integer",
        "description": "The position of the clicked result in the list"
      }
    },
    "additionalProperties": false,
    "description": "Schema for a search query event",
    "required": [
      "id",
      "type",
      "position"
    ],
    "$schema": "tvmedia/search_list_result_click/jsonschema/1-0-0"
  },
  "tvmedia/search_list_result_click/jsonschema/1-0-1": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string",
        "description": "The id of the clicked result"
      },
      "type": {
        "type": "string",
        "description": "The type of the clicked result"
      },
      "position": {
        "type": "integer",
        "description": "The position of the clicked result in the list"
      },
      "query": {
        "type": "string",
        "description": "The related search query"
      },
      "types": {
        "type": "string",
        "description": "The filters applied to the search query"
      },
      "result_view": {
        "type": "string",
        "enum": [
          "list",
          "facet"
        ],
        "description": "The context of the related search query"
      }
    },
    "additionalProperties": false,
    "description": "Schema for a search query event",
    "required": [
      "id",
      "type",
      "position",
      "query",
      "types",
      "result_view"
    ],
    "$schema": "tvmedia/search_list_result_click/jsonschema/1-0-1"
  },
  "tvmedia/search_list_result_click/jsonschema/2-0-0": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "position": {
        "type": "integer"
      },
      "query": {
        "type": "string"
      },
      "types": {
        "type": "string"
      },
      "view": {
        "type": "string",
        "enum": [
          "list",
          "facet"
        ]
      }
    },
    "additionalProperties": false,
    "description": "See https://tv4.notion.site/Search-Client-guide-b1da6c4b4deb40e9bafc5a83f18d23df for documentation.",
    "required": [
      "id",
      "type",
      "position",
      "query",
      "types",
      "view"
    ],
    "$schema": "tvmedia/search_list_result_click/jsonschema/2-0-0"
  },
  "tvmedia/search_list_result_click/jsonschema/3-1-0": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string",
        "description": "Unique identifier for the clicked search result item"
      },
      "position": {
        "type": "integer",
        "minimum": 1,
        "description": "1-indexed position of the clicked item in the list of search results."
      },
      "type": {
        "type": "string",
        "enum": [
          "Movie",
          "Series",
          "Clip",
          "SportEvent",
          "PageReference",
          "Short"
        ],
        "description": "Type of the clicked search result item"
      },
      "types": {
        "type": "array",
        "items": {
          "type": "string",
          "enum": [
            "Movie",
            "Series",
            "Clip",
            "SportEvent",
            "PageReference",
            "Short"
          ]
        },
        "description": "A list of applied filters to the search query."
      },
      "query": {
        "type": "string",
        "description": "The search query that generated the search results."
      },
      "app_version": {
        "type": "string",
        "description": "The version of the app that the user is using."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "See https://tv4.notion.site/Nordic-Search-Client-guide-V3-1-be8b3af103fb4e16b163d9460a70f6af?pvs=74 for documentation.",
    "required": [
      "id",
      "position",
      "type",
      "types",
      "query",
      "app_version"
    ],
    "$schema": "tvmedia/search_list_result_click/jsonschema/3-1-0"
  },
  "tvmedia/search_grid_result_click/jsonschema/3-1-0": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string",
        "description": "Unique identifier for the clicked search result item"
      },
      "position": {
        "type": "integer",
        "minimum": 1,
        "description": "1-indexed position of the clicked item in the list of search results."
      },
      "type": {
        "type": "string",
        "enum": [
          "Movie",
          "Series",
          "Clip",
          "SportEvent",
          "PageReference",
          "Short"
        ],
        "description": "Type of the clicked search result item"
      },
      "types": {
        "type": "array",
        "items": {
          "type": "string",
          "enum": [
            "Movie",
            "Series",
            "Clip",
            "SportEvent",
            "PageReference",
            "Short"
          ]
        },
        "description": "A list of applied filters to the search query."
      },
      "query": {
        "type": "string",
        "description": "The search query that generated the search results."
      },
      "app_version": {
        "type": "string",
        "description": "The version of the app that the user is using to perform the search."
      },
      "items_per_row": {
        "type": "integer",
        "minimum": 1,
        "description": "The number of items per row in the search results."
      },
      "navigational_steps": {
        "type": "integer",
        "minimum": 1,
        "description": "The number of navigational steps the user has taken to reach the clicked item, such as clicks on the arrow keys on remote ."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "See https://tv4.notion.site/Nordic-Search-Client-guide-V3-1-be8b3af103fb4e16b163d9460a70f6af?pvs=74 for documentation.",
    "required": [
      "id",
      "position",
      "type",
      "types",
      "query",
      "app_version"
    ],
    "$schema": "tvmedia/search_grid_result_click/jsonschema/3-1-0"
  },
  "tvmedia/search_no_input_click/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string",
        "description": "The id of the clicked item"
      },
      "type": {
        "type": "string",
        "description": "The type of the clicked item"
      },
      "position": {
        "type": "integer",
        "description": "The position of the clicked item in its section"
      },
      "section": {
        "type": "string",
        "enum": [
          "history",
          "popular",
          "popular_clips"
        ],
        "description": "The section of the clicked item"
      }
    },
    "additionalProperties": false,
    "description": "Schema for search no input click event",
    "required": [
      "id",
      "type",
      "position",
      "section"
    ],
    "$schema": "tvmedia/search_no_input_click/jsonschema/1-0-0"
  },
  "tvmedia/search_no_input_click/jsonschema/1-0-1": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "position": {
        "type": "integer"
      },
      "section": {
        "type": "string",
        "enum": [
          "history",
          "popular",
          "popular_clips"
        ]
      }
    },
    "additionalProperties": false,
    "description": "See https://tv4.notion.site/Search-Client-guide-b1da6c4b4deb40e9bafc5a83f18d23df for documentation.",
    "required": [
      "id",
      "type",
      "position",
      "section"
    ],
    "$schema": "tvmedia/search_no_input_click/jsonschema/1-0-1"
  },
  "tvmedia/search_no_input_click/jsonschema/3-1-0": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "type": {
        "type": "string",
        "enum": [
          "Movie",
          "Series",
          "Clip",
          "SportEvent",
          "PageReference",
          "Short"
        ]
      },
      "position": {
        "type": "integer"
      },
      "section": {
        "type": "string",
        "enum": [
          "history",
          "popular",
          "popular_clips"
        ]
      },
      "app_version": {
        "type": "string",
        "description": "Version of the application."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "See https://tv4.notion.site/Nordic-Search-Client-guide-V3-1-be8b3af103fb4e16b163d9460a70f6af?pvs=74 for documentation.",
    "required": [
      "id",
      "type",
      "position",
      "section",
      "app_version"
    ],
    "$schema": "tvmedia/search_no_input_click/jsonschema/3-1-0"
  },
  "tvmedia/search_query/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "query": {
        "type": "string",
        "description": "The search query"
      },
      "filters": {
        "type": "string",
        "description": "The filters applied to the search query"
      },
      "result_view": {
        "type": "string",
        "enum": [
          "list",
          "panel",
          "facet"
        ],
        "description": "The context of the search query"
      }
    },
    "additionalProperties": false,
    "description": "Schema for a search query event",
    "required": [
      "query",
      "result_view"
    ],
    "$schema": "tvmedia/search_query/jsonschema/1-0-0"
  },
  "tvmedia/search_query/jsonschema/3-1-0": {
    "type": "object",
    "properties": {
      "query": {
        "type": "string",
        "description": "The search query"
      },
      "types": {
        "type": "array",
        "items": {
          "type": "string",
          "enum": [
            "Movie",
            "Series",
            "Clip",
            "SportEvent",
            "PageReference",
            "Short"
          ]
        },
        "description": "A list of applied filters to the search query."
      },
      "view": {
        "type": "string",
        "enum": [
          "list",
          "grid"
        ],
        "description": "The context of the search query"
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "See https://tv4.notion.site/Nordic-Search-Client-guide-V3-1-be8b3af103fb4e16b163d9460a70f6af?pvs=74 for documentation.",
    "required": [
      "query",
      "view"
    ],
    "$schema": "tvmedia/search_query/jsonschema/3-1-0"
  },
  "tvmedia/search_result_item_impression/jsonschema/3-1-0": {
    "type": "object",
    "properties": {
      "query": {
        "type": "string",
        "description": "The search query string."
      },
      "search_result_item": {
        "type": "object",
        "properties": {
          "id": {
            "type": "string",
            "minLength": 1,
            "description": "The search result items' ID, could be a media or page id"
          },
          "position": {
            "type": "integer",
            "minimum": 1,
            "description": "1-indexed position of the item in the search results."
          },
          "type": {
            "type": "string",
            "enum": [
              "Movie",
              "Series",
              "Clip",
              "SportEvent",
              "PageReference",
              "Short"
            ],
            "description": "The type of the search result item"
          }
        },
        "required": [
          "id",
          "position",
          "type"
        ],
        "additionalProperties": false
      },
      "items_per_row": {
        "type": "integer",
        "minimum": 1,
        "description": "number of items shown per row in the search results if the view is grid"
      },
      "types": {
        "type": "array",
        "items": {
          "type": "string",
          "enum": [
            "Movie",
            "Series",
            "Clip",
            "SportEvent",
            "PageReference",
            "Short"
          ]
        },
        "description": "The applied filters to the search query which generated these impressions."
      },
      "view": {
        "type": "string",
        "enum": [
          "list",
          "grid"
        ],
        "description": "The view type for displaying the search results."
      },
      "app_version": {
        "type": "string",
        "description": "Version of the application."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "Schema for search result impressions. See https://tv4.notion.site/Nordic-Search-Client-guide-V3-1-be8b3af103fb4e16b163d9460a70f6af?pvs=74 for documentation.",
    "required": [
      "query",
      "search_result_item",
      "types",
      "view",
      "app_version"
    ],
    "$schema": "tvmedia/search_result_item_impression/jsonschema/3-1-0"
  },
  "iglu:com.snowplowanalytics.snowplow/web_page/jsonschema/1-0-0": {
    "type": "array",
    "description": "Schema for a Snowplow payload",
    "$schema": "iglu:com.snowplowanalytics.snowplow/web_page/jsonschema/1-0-0"
  },
  "tvmedia/short_view/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "content_media_id": {
        "type": "string",
        "description": "Metadata id of the short that was viewed."
      },
      "content_topic_id": {
        "type": "string",
        "description": "Metadata id of the first short in this topic. If this is the first short in the topic, should be the same as content_media_id "
      },
      "topic_index": {
        "type": "number",
        "minimum": 1,
        "description": "Index of how far the user has swiped within this topic (1-indexed)."
      },
      "player_session_id": {
        "type": "string",
        "description": "The session id generated by the player."
      },
      "session_id": {
        "type": "string",
        "description": "The session id generated by the client."
      },
      "app_version": {
        "type": "string",
        "description": "The version of the app."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "Schema for a short video view event",
    "required": [
      "content_media_id",
      "content_topic_id",
      "session_id",
      "app_version"
    ],
    "$schema": "tvmedia/short_view/jsonschema/1-0-0"
  },
  "tvmedia/short_summary/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "content_media_id": {
        "type": "string",
        "description": "Metadata id of the short that was viewed."
      },
      "exit_route": {
        "type": "string",
        "enum": [
          "next_short",
          "previous_short",
          "next_topic",
          "previous_topic",
          "autoplay",
          "player_exit_live",
          "player_exit"
        ],
        "description": "How the user navigated away from this short. "
      },
      "percent_seen": {
        "type": "number",
        "minimum": 0,
        "description": "Value representing the percentage of the short seen. May be larger than 1 if the short looped."
      },
      "time_spent": {
        "type": "number",
        "minimum": 0,
        "description": "The total real time spent viewing the short in milliseconds."
      },
      "player_session_id": {
        "type": "string",
        "description": "The session id generated by the player."
      },
      "session_id": {
        "type": "string",
        "description": "The session id generated by the client."
      },
      "app_version": {
        "type": "string",
        "description": "The version of the app."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "Schema for a short video summary event",
    "required": [
      "content_media_id",
      "exit_route",
      "percent_seen",
      "time_spent",
      "session_id",
      "app_version"
    ],
    "$schema": "tvmedia/short_summary/jsonschema/1-0-0"
  },
  "tvmedia/short_share/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "content_media_id": {
        "type": "string",
        "description": "Metadata id of the short that was shared."
      },
      "session_id": {
        "type": "string",
        "description": "The session id generated by the client."
      },
      "app_version": {
        "type": "string",
        "description": "The version of the app."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "Schema for a short share event",
    "required": [
      "content_media_id",
      "session_id",
      "app_version"
    ],
    "$schema": "tvmedia/short_share/jsonschema/1-0-0"
  },
  "tvmedia/core_page/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "page_name": {
        "type": "string",
        "description": "The name of the page."
      },
      "content_media_id": {
        "type": "string",
        "description": "Metadata id of the asset, if the page is a CDP."
      },
      "session_id": {
        "type": "string",
        "description": "The session id generated by the client."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "Schema for Core Tracking page event",
    "required": [
      "page_name",
      "session_id"
    ],
    "$schema": "tvmedia/core_page/jsonschema/1-0-0"
  },
  "tvmedia/core_asset_impression/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "content_media_id": {
        "type": "string",
        "description": "Metadata id of the asset that was shown."
      },
      "panel_id": {
        "type": "string",
        "description": "The metadata id of the panel containing the asset."
      },
      "panel_row_index": {
        "type": "integer",
        "minimum": 0,
        "description": "The index of the panel containing the asset."
      },
      "page_name": {
        "type": "string",
        "description": "The name of the page."
      },
      "session_id": {
        "type": "string",
        "description": "The session id generated by the client."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "Schema for Core Tracking asset impression event",
    "required": [
      "content_media_id",
      "panel_id",
      "panel_row_index",
      "page_name",
      "session_id"
    ],
    "$schema": "tvmedia/core_asset_impression/jsonschema/1-0-0"
  },
  "tvmedia/core_asset_click/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "content_media_id": {
        "type": "string",
        "description": "Metadata id of the asset that was clicked."
      },
      "panel_id": {
        "type": "string",
        "description": "The metadata id of the panel containing the asset."
      },
      "panel_row_index": {
        "type": "integer",
        "minimum": 0,
        "description": "The index of the panel containing the asset."
      },
      "page_name": {
        "type": "string",
        "description": "The name of the page."
      },
      "session_id": {
        "type": "string",
        "description": "The session id generated by the client."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "Schema for Core Tracking asset click event",
    "required": [
      "content_media_id",
      "panel_id",
      "panel_row_index",
      "page_name",
      "session_id"
    ],
    "$schema": "tvmedia/core_asset_click/jsonschema/1-0-0"
  },
  "tvmedia/core_panel_impression/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "panel_id": {
        "type": "string",
        "description": "The metadata id of the panel."
      },
      "panel_row_index": {
        "type": "integer",
        "minimum": 0,
        "description": "The index of the panel."
      },
      "page_name": {
        "type": "string",
        "description": "The name of the page."
      },
      "session_id": {
        "type": "string",
        "description": "The session id generated by the client."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "Schema for Core Tracking panel impression event",
    "required": [
      "panel_id",
      "panel_row_index",
      "page_name",
      "session_id"
    ],
    "$schema": "tvmedia/core_panel_impression/jsonschema/1-0-0"
  },
  "tvmedia/core_panel_click/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "panel_id": {
        "type": "string",
        "description": "The metadata id of the panel."
      },
      "panel_row_index": {
        "type": "integer",
        "minimum": 0,
        "description": "The index of the panel."
      },
      "page_name": {
        "type": "string",
        "description": "The name of the page."
      },
      "session_id": {
        "type": "string",
        "description": "The session id generated by the client."
      },
      "profile_id": {
        "type": "string",
        "description": "The currently active profile id for a logged in user. Don't send when user is not logged in."
      }
    },
    "additionalProperties": false,
    "description": "Schema for Core Tracking panel click event",
    "required": [
      "panel_id",
      "panel_row_index",
      "page_name",
      "session_id"
    ],
    "$schema": "tvmedia/core_panel_click/jsonschema/1-0-0"
  },
  "tvmedia/search_panel_result_click/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string",
        "description": "The id of the clicked result"
      },
      "type": {
        "type": "string",
        "description": "The type of the clicked result"
      },
      "position": {
        "type": "integer",
        "description": "The position of the clicked result in the panel"
      },
      "panel_position": {
        "type": "integer",
        "description": "Position of the panel in the search results view if the entity was presented in a panel"
      },
      "panel_type": {
        "type": "string",
        "description": "Which panel the entity was presented in if the entity was presented in a panel. Please use the key for the panel type, not the name or graphql model type. Example: Do not use SportEventPanel but use sportEvent or sportEventUpsell"
      }
    },
    "additionalProperties": false,
    "description": "Schema for a search query event",
    "required": [
      "id",
      "type",
      "position",
      "panel_position",
      "panel_type"
    ],
    "$schema": "tvmedia/search_panel_result_click/jsonschema/1-0-0"
  },
  "tvmedia/search_panel_result_click/jsonschema/1-0-1": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string",
        "description": "The id of the clicked result"
      },
      "type": {
        "type": "string",
        "description": "The type of the clicked result"
      },
      "position": {
        "type": "integer",
        "description": "The position of the clicked result in the panel"
      },
      "panel_position": {
        "type": "integer",
        "description": "Position of the panel in the search results view if the entity was presented in a panel"
      },
      "panel_type": {
        "type": "string",
        "description": "Which panel the entity was presented in if the entity was presented in a panel. Please use the key for the panel type, not the name or graphql model type. Example: Do not use SportEventPanel but use sportEvent or sportEventUpsell"
      },
      "query": {
        "type": "string",
        "description": "The related search query"
      }
    },
    "additionalProperties": false,
    "description": "Schema for a search query event",
    "required": [
      "id",
      "type",
      "position",
      "panel_position",
      "panel_type",
      "query"
    ],
    "$schema": "tvmedia/search_panel_result_click/jsonschema/1-0-1"
  },
  "tvmedia/search_panel_result_click/jsonschema/1-0-2": {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "position": {
        "type": "integer"
      },
      "panel_position": {
        "type": "integer"
      },
      "panel_type": {
        "type": "string"
      },
      "query": {
        "type": "string"
      }
    },
    "additionalProperties": false,
    "description": "See https://tv4.notion.site/Search-Client-guide-b1da6c4b4deb40e9bafc5a83f18d23df for documentation.",
    "required": [
      "id",
      "type",
      "position",
      "panel_position",
      "panel_type",
      "query"
    ],
    "$schema": "tvmedia/search_panel_result_click/jsonschema/1-0-2"
  },
  "tvmedia/unstructured_insights_event/jsonschema/1-0-0": {
    "type": "object",
    "properties": {
      "eventName": {
        "type": "string",
        "maxLength": 100,
        "description": "The name of the event"
      },
      "eventVersion": {
        "type": "string",
        "maxLength": 10,
        "description": "The version of the event"
      },
      "data": {
        "type": "string",
        "maxLength": 1024,
        "description": "The data of the event as a json string"
      }
    },
    "additionalProperties": false,
    "description": "Unstructured Insights Event 1.0.0",
    "required": [
      "eventName",
      "eventVersion",
      "data"
    ],
    "$schema": "tvmedia/unstructured_insights_event/jsonschema/1-0-0"
  }
} as const;
