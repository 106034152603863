import { authenticationWebRefreshControllerRefresh } from '@nordic-web/rest-codegen/generated/auth'
import { isTokenExpired } from './is-token-expired'
import { shouldRefreshAccessToken } from './should-refresh-access-token'

type Listener = () => void
type Token = string | undefined

type AuthState = {
  refreshToken: Token
  accessToken: Token
  profileId: string
  isAuthStoreInitialized: boolean
  isLoggedIn: boolean
  tierOverrideId?: string
}

export type Storage = {
  getToken(): Promise<string | undefined>
  setToken(value: string): Promise<void>
  removeToken(): Promise<void>
  setProfileId(value: string): Promise<void>
  getProfileId(): Promise<string | undefined>
  setTierOverrideId(value?: string): Promise<void>
  getTierOverrideId(): Promise<string | undefined>
}

export type AuthenticationStore = ReturnType<typeof createAuthenticationStore>

type AuthError = {
  message: string
}

export function createAuthenticationStore(storage: Storage, clientName: string, onError: (error: AuthError) => void) {
  let listeners: Listener[] = []
  let state: AuthState = {
    refreshToken: undefined,
    accessToken: undefined,
    profileId: '',
    isAuthStoreInitialized: false,
    isLoggedIn: false,
    tierOverrideId: undefined,
  }
  let refreshAccessTokenPromise: Promise<void> | null = null
  let refreshIntervalId: ReturnType<typeof setInterval> | null = null
  let lastRefreshFailureTime: number | null = null

  function startRefreshInterval() {
    if (refreshIntervalId) return
    refreshIntervalId = setInterval(() => {
      authenticationStore.getValidAccessToken()
    }, 30_000)
  }

  function stopRefreshInterval() {
    if (refreshIntervalId) {
      clearInterval(refreshIntervalId)
      refreshIntervalId = null
    }
  }

  function setAndStoreProfileId(profileId: string) {
    setState({ profileId })
    storage.setProfileId(profileId)
  }

  function setState(newState: Partial<AuthState>) {
    const isLoggedInPrev = state.isLoggedIn
    const merged = { ...state, ...newState }
    state = { ...merged, isLoggedIn: !!merged.refreshToken }

    if (state.isLoggedIn !== isLoggedInPrev) {
      if (state.isLoggedIn) {
        startRefreshInterval()
      } else {
        stopRefreshInterval()
      }
    }
  }

  // If the server is having issues we dont want to spam it with requests
  function canAttemptRefresh() {
    if (!lastRefreshFailureTime) return true
    return Date.now() - lastRefreshFailureTime >= 30_000
  }

  const authenticationStore = {
    async initialize() {
      if (state.isAuthStoreInitialized) {
        return
      }

      const tierOverrideId = await storage.getTierOverrideId()
      setState({ tierOverrideId })

      const profileId = await storage.getProfileId()
      setState({ profileId })

      const refreshToken = await storage.getToken()
      setState({ refreshToken })
      await this.refreshAccessToken()
      setState({ isAuthStoreInitialized: true })
      emitChange()
    },
    async getValidAccessToken() {
      if (state.refreshToken && shouldRefreshAccessToken(state.accessToken) && canAttemptRefresh()) {
        await this.refreshAccessToken()
      }

      // We throw an error here to avoid calling the graphql api with an expired token
      // This happens when the above refreshAccessToken fails because of network issues or bot protection
      if (isTokenExpired(state.accessToken)) {
        const errorMessage = 'Access token is expired'
        onError({
          message: errorMessage,
        })
        throw new Error(errorMessage)
      }

      // Fail ALL reuqests if we failed to refresh the token and there is no old token to use
      if (state.refreshToken && !state.accessToken) {
        const errorMessage = 'Access token is missing'
        onError({
          message: errorMessage,
        })
        throw new Error(errorMessage)
      }

      return state.accessToken
    },
    refreshAccessToken() {
      if (!state.refreshToken) return

      if (!refreshAccessTokenPromise) {
        refreshAccessTokenPromise = (async () => {
          const { refreshToken, profileId } = state
          const [profile_id, is_child] = profileId ? profileId.split('|') : []

          try {
            if (refreshToken) {
              const { data, response } = await authenticationWebRefreshControllerRefresh({
                body: {
                  refresh_token: refreshToken,
                  client_id: clientName,
                  ...(profile_id ? { profile_id } : {}),
                  is_child: !!is_child,
                },
                throwOnError: false,
              })

              console.info('Refreshing access token')

              if (response.status !== 200) {
                throw { status: response.status }
              }

              if (!data?.access_token) {
                const errorMessage = 'Access token missing in refresh response'
                onError({
                  message: errorMessage,
                })
                throw new Error(errorMessage)
              }

              setState({ accessToken: data.access_token })

              lastRefreshFailureTime = null
              emitChange()
            }
          } catch (error) {
            if (error && typeof error === 'object' && 'status' in error && error.status === 401) {
              console.warn('Logging out because of a 401 error when refreshing access token')
              this.logout()
            } else {
              const errorMessage = 'Error while refetching access token'
              onError({
                message: errorMessage,
              })
              lastRefreshFailureTime = Date.now()
              console.error(errorMessage, error)
            }

            throw error
          } finally {
            refreshAccessTokenPromise = null
          }
        })()
      }

      return refreshAccessTokenPromise
    },
    logout() {
      setState({ accessToken: undefined })
      setAndStoreProfileId('')
      this.setRefreshToken(undefined)
    },
    login(newRefreshToken: Token, newAccessToken: Token) {
      setState({ accessToken: newAccessToken })
      this.setRefreshToken(newRefreshToken)
    },
    setRefreshToken(token: Token) {
      setState({ refreshToken: token })
      if (token) {
        storage.setToken(token)
      } else {
        storage.removeToken()
      }
      emitChange()
    },
    async changeProfile(id: string, isChild: boolean) {
      const profileId = isChild ? `${id}|isChild` : id
      setAndStoreProfileId(profileId)
      await this.refreshAccessToken()
      emitChange()
    },
    setTierOverrideId(tierOverrideId?: string) {
      storage.setTierOverrideId(tierOverrideId)
      setState({ tierOverrideId })
      emitChange()
    },
    subscribe(listener: Listener) {
      listeners = [...listeners, listener]
      return () => {
        listeners = listeners.filter((l) => l !== listener)
      }
    },
    getSnapshot() {
      return state
    },
  }

  function emitChange() {
    for (const listener of listeners) {
      listener()
    }
  }

  return authenticationStore
}
