import React from 'react'
import { ProgressBar } from '@nordic-web/ui-components'
import { useProgressQuery } from '@/hooks/progress/use-progress-query'

type ProgressBarWithQueryProps = {
  videoAssetId: string
}

export const ProgressBarWithQuery = ({ videoAssetId }: ProgressBarWithQueryProps) => {
  const { progress } = useProgressQuery(videoAssetId)

  const percent = progress?.percent

  if (percent) {
    return <ProgressBar percentage={percent} />
  }

  return null
}
