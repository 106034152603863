import { useCallback, useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { useReno } from '@nordic-web/reno/hooks/use-reno'
import { parseJwt } from '@nordic-web/utils/authentication/token'
import { authenticationStore, useAuthenticationStore } from '@/features/auth/authentication-store'
import type { RenoNotification } from '@/features/reno/types'
import { useHandleRenoNotification } from './use-handle-reno-notification'

export const RenoNotificationListener = () => {
  const { isLoggedIn, accessToken } = useAuthenticationStore()
  const [publishedNotifications, setPublishedNotifications] = useState<string[]>([])
  const renoHandlers = useHandleRenoNotification()

  const onError = useCallback(
    (error: Error) => {
      Bugsnag.notify('An error occurred when fetching reno notifications ', (event) => {
        event.addMetadata('details', {
          rawError: error,
          expiryTime: accessToken ? parseJwt(accessToken)?.exp : 'No access token',
        })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken]
  )

  const getAccessToken = useCallback(() => authenticationStore.getValidAccessToken(), [])

  const { socketNotification } = useReno({
    getAccessToken,
    onError,
    skip: !isLoggedIn,
  })

  const handleNotification = useCallback(
    (notification: RenoNotification) => {
      const isPublished = publishedNotifications.includes(notification?.id)
      if (isPublished) return

      switch (notification.type) {
        case 'LOGOUT_ALL':
          renoHandlers.handleLogoutAll()
          break
        case 'PROFILES_CHANGED':
          renoHandlers.handleProfileChange()
          break
        case 'SUBSCRIPTION_CHANGED':
          renoHandlers.handleSubscriptionChange()
          break
        case 'SERVICE_MESSAGES_UPDATED':
          renoHandlers.handleServiceMessagesUpdated()
          break
        default:
          console.error('Unhandled reno notification type', notification.type)
          break
      }

      setPublishedNotifications([...publishedNotifications, notification.id])
    },
    [publishedNotifications, renoHandlers]
  )

  useEffect(() => {
    if (socketNotification) {
      handleNotification(socketNotification)
    }
  }, [socketNotification, handleNotification])

  return null
}
