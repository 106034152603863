import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import useWebSocket from 'react-use-websocket'
import type { Options as WebsocketOptions } from 'react-use-websocket'
import {  getNotifications, GetNotificationsResponse} from '@nordic-web/rest-codegen/generated/reno'

type RenoNotification = GetNotificationsResponse['notifications'][0]


type Options = {
  onError?: (error: Error) => void
  skip?: boolean
  userAgent?: string
  getAccessToken: () => Promise<string | undefined>
}

export const useReno = (options: Options) => {
  const { onError, skip, getAccessToken } = options

  const {
    data: renoData,
    error,
  } = useQuery({
    queryKey: ['reno'],
    enabled: !skip,
    staleTime: Infinity,
    gcTime: Infinity,
    queryFn: async () => {
      let token
      try {
        token = await getAccessToken()
      } catch (error) {
        console.error('Error getting access token for reno request', error)
      }

      if (!token) {
        return null
      }
      const response = await getNotifications({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        query: {
          since: Date.now()
        },
        throwOnError: true
      })
      return response.data
    },
  })

  useEffect(() => {
    if (error && onError) {
      onError(error)
    }
  }, [error, onError])

  const socketUrl = renoData?.realtimeStream?.url ?? null
  const shouldConnect = !!socketUrl

  const websocketOptions: WebsocketOptions = {
    headers: {
      'User-Agent': options.userAgent ?? '',
    },
    retryOnError: true,
    // Default is 20 but might as well keep it here to be explicit
    reconnectAttempts: 20,
    heartbeat: {
      interval: 60_000 * 5,
      timeout: 60_000 * 10,
    },
    filter: (message) => message.data !== 'pong',
    reconnectInterval: (lastAttemptNumber: number) => Math.min(1000 * 2 ** lastAttemptNumber, 60_000),
    shouldReconnect: (closeEvent) => true,
    // To reuse WebSocket instances across hook instances
    share: true,
  }

  const getUrl = useCallback(async () => {
    const token = await getAccessToken()
    return socketUrl + `?token=${token}`
  }, [getAccessToken, socketUrl])

  const socket = useWebSocket<RenoNotification>(getUrl, websocketOptions, shouldConnect)

  return { socketNotification: socket.lastJsonMessage }
}
