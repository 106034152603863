import { client as authClient } from '@nordic-web/rest-codegen/generated/auth/client.gen'
import { client as renoClient } from '@nordic-web/rest-codegen/generated/reno/client.gen'
import { client as serviceMessagesClient } from '@nordic-web/rest-codegen/generated/service-messages/client.gen'
import { client as subscriptionClient } from '@nordic-web/rest-codegen/generated/subscription/client.gen'
import { clientNameAndVersionHeaders } from '@/helpers/client-name-and-version-headers'
import { nextConfig } from '@/helpers/env'

export function configureRestClients() {
  authClient.setConfig({
    baseUrl: nextConfig.string('AUTH_API'),
  })

  subscriptionClient.setConfig({
    baseUrl: nextConfig.string('SUBSCRIPTION_API'),
  })

  serviceMessagesClient.setConfig({
    baseUrl: nextConfig.string('SERVICE_MESSAGES_API'),
    headers: {
      ...clientNameAndVersionHeaders,
    },
  })

  renoClient.setConfig({
    baseUrl: nextConfig.string('RENO_API'),
    headers: {
      ...clientNameAndVersionHeaders,
    },
  })
}
